.App {
    /* font-family: sans-serif; */
    /* text-align: center; */
  }
  
  /* a {
    text-decoration: none;
    margin: 10px;
  } */
  
  section {
    height: 500px;
    background-color: lightcoral;
  }
  
  .fadeIn {
    animation: 0.2s fadeIn forwards;
  }
  
  .fadeOut {
    animation: 0.5s fadeOut forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(0px, 20px);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(0px, -20px);
      opacity: 0;
    }
  }
  