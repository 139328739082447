/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* #BASE */

:root{
  --color-primary: #002B49;
  --color-secondary: #fff6e5;
  --color-blue: #002B49;
  --color-beige: #fff6e5;
  --color-blue: #002B49;
  /* --swiper-navigation-sides-offset: -20px; */
  /* --swiper-navigation-size: 32px; */
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  color: var(--color-blue);
  font-size: 32px!important;
}

* {
  box-sizing: border-box;
  position: relative;
  min-width: 0;
  user-select: none;
}
body {
  min-height: 100dvh;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  overflow-wrap: break-word;
  text-wrap: balance;
  font-size: inherit;
  font-weight: inherit;
}
/* p {
  text-wrap: pretty;
} */

@font-face {
  font-family: Atak;
  src: url("/public/fonts/Atak/Atak Regular.otf") format("opentype");
  font-style: normal;
}
/* @font-face {
  font-family: Atak;
  src: url("/public/fonts/Atak/Atak Bold.otf") format("opentype");
  font-style: bold;
} */


@font-face {
  font-family: ZapfinoExtraLT;
  src: url("/public/fonts/Zapfino/ZapfinoExtraLT-One.otf") format("opentype");
}
@font-face {
  font-family: Coconat;
  src: url("/public/fonts/Coconat/Coconat-Regular.otf") format("opentype");
}

.fontZapfino {
  font-family: 'ZapfinoExtraLT';
}
.fontAtak {
  font-family: 'Atak';
}
.fontCoconat {
  font-family: Coconat; 
}
.textWhite {
  color: #fff;
}
.textBlue {
  color: var(--color-blue);
}
.font5 {
  font-size: 5vh;
}
.font6 {
  font-size: 6vh;
}
.font8 {
  font-size: 8vh;
}
.font9 {
  font-size: 9vh;
}
.font10 {
  font-size: 10vh;
}
.font12 {
  font-size: 12vh;
}
.font12px {
  font-size: 12px;
}
.block {
  display: block;
}

h1 {
  text-transform: capitalize;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App, .fadeIn {
  height: 100%;
  width: 100%;
}
a {
  text-decoration: none;
}

/* # LAYOUT */
.flex-1 {
  flex: 1;
}
.pH40 {
  padding-left: 40px;
  padding-right: 40px;
}
.pH20 {
  padding-left: 20px;
  padding-right: 20px;
}
.pH80 {
  padding-left: 80px;
  padding-right: 80px;
}
.pH90 {
  padding-left: 80px;
  padding-right: 80px;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb80 {
  margin-bottom: 80px;
}
.mr40 {
  margin-right: 40px;
}
.container {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #fff;
}
.container.blue, .column.blue {
  background-color: var(--color-primary);
}
.container.beige, .column.beige {
  background-color: var(--color-secondary);
}
.bgBlue {
  background-color: var(--color-primary);
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.text-justify {
  text-align: justify;
}
.alignLeft .textLeft{
  text-align: left;
}
.alignRight {
  text-align: right;
}
.textAlignCenter {
  text-align: center;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.align-items-flex-end {
  align-items: end;
}

/* # COMPONETS */
@keyframes topToBottom {
  0% {
    top: -60px;
    opacity: 0;
    /* transform: scale(.9); */
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
@keyframes bottomToTop {
  0% {
    top: 60px;
    opacity: 0;
    /* transform: scale(.9); */
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
.logo {
  max-width: 34vh;
  animation: topToBottom 1.4s ease-out;
}
.animation-topToBottom {
  animation: topToBottom 1s ease-out;
}
.animation-bottomToTop {
  animation: bottomToTop .5s ease-out;
}
.homeButton {
  display: flex;
  height: 30px;
  display: flex;
  align-items: center;
}
.homeButton.dark {
  background-color: #000;
}
.homeButton svg {
  height: 90%;
}
.backButton {
  display: flex;
}
.backButton:hover, .homeButton:hover {
  cursor: pointer;
}
.backButton > div {
  align-items: center;
  /* background-color: black; */
}
.backButton span, .homeButton span {
  padding: 5px 6px;
  letter-spacing: 4px;
  color: var(--color-secondary);
  /* font-weight: bold; */
}
.backButton.black span, .homeButton span { 
  color: #000;
  /* font-weight: bold; */
}
.backButton.dark span {
  background-color: black;
  color: var(--color-secondary);
}
.backButton i {
  /* margin-right: 10px; */
}
.splitContent {
  flex: 1;
  max-width: 50%;
}
.splitContent.column {
  overflow-y: scroll;
  padding-bottom: 30px;
  
} 
.splitContent.column::-webkit-scrollbar {
  /* display: none; */
}

.containerDetail {
  display: flex;
  flex: 1;
}
.containerDetail > div {
  display: flex;
  /* flex: 1; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center;   */
  /* padding: 0 10px; */
  /* height: 100%; */
}
label {
  font-weight: bold;
  font-family: Atak;
}
p {
  font-family: Atak;
}

.scrollY {
  /* overflow-y: scroll; */
  /* min-height: 200px; */
  /* mask-image: -webkit-gradient(linear, left top, left bottom,  
  color-stop(0.00,  rgba(0,0,0,1)),
  color-stop(0.35,  rgba(0,0,0,1)),
  color-stop(1.00,  rgba(0,0,0,0))); */

  /* -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0))); */
    /* min-height: 400px; */
}
.scrollY::-webkit-scrollbar {
  display: none;
}
.blankSapce {
  /* height: 90px; */
}

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.tabs li {
  display: inline-block;
  cursor: pointer;
  /* margin-right: 5px; */
  /* background-color: var(--color-primary); */
  /* color: #fff; */
  /* border-radius: 2px; */
  /* padding: 2px 6px; */

}
.tab-content {
  display: none;
}
.tab-content.active {
  display: block;
}
.line {
  flex: 1;
  border: .9px solid #000;
}
.icon svg {
  width: 100px;
  /* display: inline-block; */
  /* text-align: center; */
}
.icon.blue svg {
  fill: var(--color-blue);
}
.buttonTab {
  border: 1px solid #000;
  /* background-color: #fff; */
  max-width: 160px;
  font-family: Atak;
  letter-spacing: 1.2px;
  padding: 10px 0;
  margin: 0 20px;
  text-align: center;
  text-transform: capitalize;
  border: none;
  opacity: .5;
  min-width: 90px;
}
.buttonTab div {
  box-shadow: 0px 7px 6px 2px #999;
  background-color: rgba(256, 256, 256, .3);
  margin-bottom: 14px;
}
.buttonTab .icon {
  padding: 8px 0;
}
.buttonTab svg {
  width: 6vw;
}
.buttonTab.active {
  /* background-color: #000; */
  opacity: 1;
  /* color: #fff; */
}
.buttonTab:hover {
  cursor: pointer;
}

/* WIREFRAME */
nav {
  /* padding: 0 10px; */
  padding-top: 5vh;
}
.button {
  text-decoration: none;
  background-color: var(--color-blue);
  color: #F4F4F4;
  padding: 6px 0;
  border: none;
  font-weight: bold;
  font-size: 12px;
  min-width: 150px;
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: Atak;
  font-size: 14px;
  letter-spacing: 4px;
}

.iconBack {
  background-image: url('/public/assets/backButton.svg');
  width: 30px;
  height: 30px;
  display: inline-block;
}
.iconBackBlack {
  background-image: url('/public/assets/backButtonBlack.svg');
  width: 30px;
  height: 30px;
  display: inline-block;
}


.buttonXL {
  background-color: #ddd;
  /* padding: 30px; */
  margin: 10px 0;
  min-width: 200px;
  max-width: 300px;
  /* height: 200px; */
  text-align: center;
  border-radius: 5px;
}
.buttonXL img {
  width: 100%;
}
.buttonXL {
  text-decoration: none;
}
.buttonXL:hover {
  background-color: #ededed;
}

/* .backButton {

} */

.carrousel {
  background-color: #ddd;
  width: 80%;
  height: 100%;
  align-self: center;
}
.fakeImage {
  background-color: #efefef;
  width: 200px;
  height: 300px;
}

/* detail page */
.section nav, .detailPage nav {
  /* padding-top: 14vh; */
  justify-content: space-between;
  /* padding-bottom: 10vh; */
  padding-left: 60px;
}
.detailPage h1 {
  margin: 6vh 0;
  font-size: 4vh;
}
/* MAP */
/* .mapContainer {
  width: 17727px;
} */
.bgLine {
  background-image: url('/public/assets/line.png');
  background-repeat: no-repeat;
  background-position-y: 30vh;
  /* background-position-x: 10vh; */
  background-size: contain;
}

/* # MAP */
@keyframes animationTest {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.spotTest {
  /* animation: scaleAnimation s infinite; Ajusta la duración y otras propiedades según tus necesidades */
}
.lobby {
  /* transition: 1s ease all; */
  /* opacity: 0; */
  /* transform-origin: 50% 50%; */
  animation: animationTest .4s;
}

.spotTest:hover {
  /* fill: rgba(256, 256, 256, .7); */
  cursor: pointer;
  /* opacity: .5; */
  transform: scale(2, 2);
}
.mapSpot {
  background-image: url('/public/assets/backButtonBlack.svg');
  /* animation: blink 2s infinite; */
  fill: var(--color-blue);
  stroke: var(--color-beige);
  stroke-width: 6px;
}

.mapSpot:hover, .mapSpot.active {
  stroke: var(--color-blue);
  fill: var(--color-beige);
  cursor: pointer;
}
.buttonMenu {
  width: 90px;
  height: 90px;
  border-radius: 99px;
  position: absolute;
  background-color: #ddd;
  left: 20px;
  top: 20px;
  border: none;
}
.buttonMenu:hover {
  cursor: pointer;
  background-color: #eee;
}
.quickView {
  max-width: 350px;
  /* height: 250px; */
  background-color: var(--color-beige);
  position: absolute;
  border-radius: 9px;
  z-index: 99;
  padding: 30px 30px;
}
.quickView span {
  font-size: 40px;
  color: #C8A977;
  line-height: 20px;
}
.quickView h4 {
  font-size: 20px;
  letter-spacing: 2.5px;
}
.quickView p {
  line-height: 19px;
}

/* # SLIDES */

.swiper {
  width: 80%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}
/* .swiper-wrapper {
  transition: 2s ease all!important;
} */
.swiper-slide {
  /* transition: 2s ease height!important; */
  /* transition: 2s ease width!important; */
}
.swiper-zoom-target {
  /* transition: 2s ease all!important; */
  /* transition: 2s ease height!important; */
}

.swiper-slide img {
  display: block;
  filter: brightness(0.6);
  width: 100%;
  /* height: 100%; */
}

/* ==== */
.swiper-zoom-container {
  /* transition: .4s ease width; */
  /* border: 80px solid red; */
}
.border {
  /* border: 0px solid var(--color-primary); */
  /* border-style: solid; */
  /* border-color: red; */
  /* overflow: hidden; */
  /* transition: .8s ease border; */
  /* border-left: 120px solid var(--color-primary); */
  /* border-right: 120px solid var(--color-primary); */
}
.swiper-zoom-container {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* transition: .4s ease border; */
}
.swiper-wrapper {
  /* height: 100%; */
}
.swiper-slider-content {
  position: absolute;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  display: flex;
  /* z-index: 99; */
  /* display: none; */
  /* animation: topToBottom 1.4s ease-out; */
}
.swiper-slider-content h2 {
  /* margin-bottom: -8vh; */
  /* margin-bottom: -20px; */
  /* position: absolute; */
  /* top: 10%; */
  line-height: 0px;
}
.coverflow .swiper-slider-content h1 {
  /* position: absolute; */
  /* top: 40%; */
}
.active .swiper-slider-content {
  /* display: flex!important; */
}
.swiper-zoom-target {
  filter: brightness(.6);
}
.swiper-zoom-container > div {
  width: 100%;
  /* height: 100%; */
  object-fit: contain; /* Asegura que la imagen se ajuste correctamente dentro del contenedor */
}
/* .title {
  color: #fff;
} */
.btnNext, .btnPrev {
  border-radius: 99px;
  border-color: #eee;
  background-color: transparent;
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 999;
  color: #fff;
}
.btnNext {
  /* width: 100px; */
  /* height: 40px; */
  /* z-index: 999; */
  right: 10px;
}
.btnPrev {
  /* position: absolute; */
  /* width: 100px; */
  /* height: 40px; */
  /* z-index: 999; */
  left: 10px;
}

.swiper-detail-container {
  width: 100%;
}
.swiper-fade .swiper-slide {
  transition: 1s ease all;
  /* transition-duration: 1s!important; */
}
.swiper-wrapper{
  /* transition-duration: 1s !important; */
}


/* # MASONRY */
.my-masonry-grid {
  display: flex;
  background-color: #fff;
  /* padding-top: 200px; */
  margin-left: -30px; /* Ajusta según sea necesario para eliminar el espacio entre las columnas */
}

.my-masonry-grid_column {
  padding-left: 30px; /* Ajusta según sea necesario para eliminar el espacio entre las columnas */
  background-clip: padding-box;
}
.my-masonry-grid img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 30px;
}
.my-masonry-grid img {
  max-width: 100%;
  height: auto;
  display: block;
}
.my-masonry-grid img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* # VIDEO */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  filter: brightness(.4);
}
.homeControls {
  width: 100%;
  position: fixed;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 99;
  flex-direction: column;
}
.homeControls img {
  width: 90vh;
}
.lngControls {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-family: Coconat;
}
.lngControls:hover {
  cursor: pointer;
}
.lngControls div {
  margin-top: 20px;
  margin-right: 20px;
  border: 1px solid #fff;
  padding: 5px 20px;
}

.lngSwitch {
  flex-direction: column;
}
.lngSwitch button {
  border: none;
  /* text-decoration: underline; */
  /* background-color: #000; */
  /* color: #fff; */
  font-size: 14px;
  color: var(--color-blue);
  text-transform: uppercase;
  font-family: Coconat;
  background-color: transparent;
}
.lngSwitch.dark button {
  color: var(--color-beige);
}
.lngSwitch button:hover {
  cursor: pointer;
}
.lngSwitch button.disabled {
  /* background-color: #999; */
  color: var(--color-blue);
  opacity: 0.6;
}
.lngSwitch.dark button.disabled {
  color: var(--color-beige);
  opacity: 0.6;
}

.amenities_list {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  list-style-type: none;
  margin: 0;
}
.amenities_list::-webkit-scrollbar {
  display: none;
}
.amenities_list li {
  display: inline-block;
  /* background-color: red; */
  margin-right: 10px;
  padding: 10px 6px;
}
.amenityIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}
.amenityIcon svg {
  width: 60px;
  height: 60px;
  stroke: #C8A977;
  /* background-color: #444; */
}
.amenityIcon span {
  /* inline-size: 150px; */
  text-align: center;
  white-space: normal;
  height: 40px;
  /* overflow-wrap: break-word; */
}

/* TEST */
/* 
.swiper-button-next {
  width: 200px;
height: 200px;
z-index: 99;
background-color: blue;
display: block;
position: absolute;
top: 0;
right: 0;
} */
.swiper-slide-fully-visible.asdf .fashion-slider-title {
  /* transform: translate3d(-130%, 0px, 0px); */
  transform: translate3d(0%, 0px, 0px);
  transition-duration: 0ms;
}

.swiper-slide-fully-visible.asdf .fashion-slider-title-text {
  /* transition: 1000ms; */
  /* color: rgb(255, 255, 255); */
}
.asdf .fashion-slider-scale {
  /* transform: scale(0.6); */
  transform: scale(1);
}
.swiper-slide-fully-visible .fashion-slider-scale {
  /* transform: scale(0.6); */
  /* transform: scale(1); */
}
.swiper-slide-fully-visible.asdf .fashion-slider-scale  img {
  /* transform: translate3d(60%, 0px, 0px) scale(1.2); */
  transform: translate3d(0%, 0px, 0px) scale(1.2);
  /* transition-duration: 1300ms; */
  transition-duration: 0ms;
}

/* gallery */
/* .popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.popup-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
} */
.custom-zoom [data-rmiz-modal-overlay="hidden"] {
  /* background-color: rgb(56, 58, 89, 0); */
}
.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: rgb(0,0,0, .88);
}


.swiper-slide-active {
  transform: translate3d(0, 0, 0) !important;
}

.amenitiesSwiper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.amenities_list {
  /* scrollbar-width: none; */
  padding: 0;
  overflow: hidden;
}
.amenitiesSwiper > span > svg:hover {
  cursor: pointer;
  opacity: 0.4;
}
.amenitiesSwiper .prev {
  margin-right: 6px;
}
.amenitiesSwiper .next {
  margin-left: 6px;
}